var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all-online-tests-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('div',[_c('v-card',{staticClass:"mx-3 mt-3 py-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.viewPreviousTestResultsOrReports}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-book-open")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v("View Previous Test "+_vm._s(_vm.currentUser.is_student ? 'Results' : 'Reports'))]),(_vm.currentUser.is_student)?_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v("See what you scored in your previous tests")]):(_vm.currentUser.is_admin ||  _vm.currentUser.is_faculty)?_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v("View the reports of previously conducted online tests")]):_vm._e()],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)],1),_c('div',[_c('intract-smart-list',{ref:"all-online-tests-smart-list",staticClass:"mt-2",attrs:{"endpoint":_vm.onlineTestsEndpoint,"paginated":""},scopedSlots:_vm._u([{key:"list-item",fn:function({ item: test }){return [_c('v-card',{staticClass:"mx-3 mb-2",attrs:{"to":{ name: 'TestDetails', params: { testId: test.id } }}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"primary--text"},[_vm._v(_vm._s(test.subject.name)+" | "+_vm._s(test.subject.room.sections))]),_c('v-list-item-title',[_vm._v(_vm._s(test.tname))]),_c('v-list-item-subtitle',{staticClass:"font-weight-medium"},[(
                        _vm.moment(test.startsAt).date() ==
                        _vm.moment(test.endsAt).date()
                      )?_c('span',[_vm._v(_vm._s(_vm.moment(test.startsAt).format("lll"))+" - "+_vm._s(_vm.moment(test.endsAt).format("hh:mm A")))]):_c('span',[_vm._v(_vm._s(_vm.moment(test.startsAt).format("lll"))+" - "+_vm._s(_vm.moment(test.endsAt).format("lll")))])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(test.description))])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-right")])],1)],1)],1)]}}])})],1)])],1),_c('choose-room-dialog',{attrs:{"visible":_vm.showRoomDialog},on:{"roomSelected":(r) => {
        _vm.selectedRoom = r;
        _vm.showRoomDialog = false;
      }}}),(_vm.formFields)?_c('intract-create-edit-sheet',{attrs:{"persistent":"","title":"Create Online Test","visible":_vm.onlineTestCreateSheet.visible,"fields":_vm.formFields,"submit-button-text":"Create","endpoint":_vm.endpoints.onlineTestViewSet,"edit-id":_vm.onlineTestCreateSheet.editId,"create-success-message":_vm.onlineTestCreateSheet.createSuccessMessage,"data-object":_vm.onlineTestCreateSheet.obj},on:{"close":function($event){_vm.onlineTestCreateSheet.visible = false;
      _vm.onlineTestCreateSheet.editId = null;},"objectCreated":_vm.onlineTestCreated,"updateObject":(obj) => (_vm.onlineTestCreateSheet.obj = obj)}}):_vm._e(),_c('v-fab-transition',[(!_vm.isLoading && !_vm.currentUser.is_student)?_c('v-btn',{attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.onlineTestCreateSheet.visible = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }